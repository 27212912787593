import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

// HackIB Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUIcnDk6MkrV1HPWvq6vpMpWwgjjwWNP8",
  authDomain: "hack-ib.firebaseapp.com",
  databaseURL: "https://hack-ib.firebaseio.com",
  projectId: "hack-ib",
  storageBucket: "hack-ib.appspot.com",
  messagingSenderId: "148788066541",
  appId: "1:148788066541:web:8b0a1fa36f110defdc74e4",
}

firebase.initializeApp(firebaseConfig)

// Initialize Firebase
export default firebase
