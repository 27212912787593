import React from "react"
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import rootReducer from "."
import thunk from "redux-thunk"
import { reduxFirestore, getFirestore } from "redux-firestore"
import fbConfig from "../config/fbConfig"

const store = createStore(
  rootReducer,
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ getFirestore })),
    reduxFirestore(fbConfig)
  )
)

export default ({ element }) => <Provider store={store}>{element}</Provider>
