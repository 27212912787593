/** REDUX STATE, ACTIONS, REDUCERS & FIREBASE SUPPORT FUNCTIONS **/
import { getFirestore } from "redux-firestore"

// Initial State
const initialState = {
  user: null,
  isLoggedIn: false,
  firebaseQuestions: [],
  loading: false,
}

// Actions
const ADD_QUESTION = "ADD_QUESTION"
const REMOVE_QUESTION = "REMOVE_QUESTION"
const CLEAR_ALL_QUESTIONS = "CLEAR_ALL_QUESTIONS"
const GET_QUESTIONS = "GET_QUESTIONS"
const SET_USER = "SET_USER"
const SIGNOUT_USER = "SIGNOUT_USER"
const SET_LOADING = "SET_LOADING"

export const addQuestion = question => dispatch => {
  addToFirebase(question)
  dispatch({
    type: ADD_QUESTION,
    payload: question,
  })
}

export const removeQuestion = question => dispatch => {
  removeFromFirebase(question)
  dispatch({
    type: REMOVE_QUESTION,
    payload: question,
  })
}

export const clearAllQuestions = user => dispatch => {
  clearFirebase(user)

  dispatch({
    type: CLEAR_ALL_QUESTIONS,
    payload: null,
  })
}

export const setUser = user => dispatch => {
  let string = user.expires_at + "/" + user.email
  localStorage.setItem("hackib_token", string)
  dispatch({
    type: SET_USER,
    payload: user,
  })

  // dispatch({
  //   type: SET_LOADING,
  //   payload: null,
  // })

  addUserToFirebase(user)

  // setTimeout(
  //   () =>
  //     dispatch({
  //       type: SET_USER,
  //       payload: user,
  //     }),
  //   1250
  // )
}

export const signOut = () => dispatch => {
  localStorage.removeItem("hackib_token")
  window.location.href = "./"
  dispatch({
    type: SET_LOADING,
    payload: null,
  })

  setTimeout(() => {
    dispatch({
      type: SIGNOUT_USER,
      payload: null,
    })
  }, 1250)
}

// See number of registered users (on home page)
export const getUsers = () => dispatch => {
  const firestore = getFirestore()
  let emails = []

  firestore
    .collection("users")
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        emails.push(doc.data().email)
      })
    })
    .then(() => {
      let numUsers = Object.keys(emails).length
      console.log(`${numUsers} users signed up for IB Vine.`)
    })
}

export const getQuestions = user => dispatch => {
  dispatch({
    type: SET_LOADING,
    payload: null,
  })

  const firestore = getFirestore()
  let final = []

  firestore
    .collection("questions")
    .where("user.email", "==", user.email)
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        let q = {
          type: doc.data().type,
          question: doc.data().question,
          answer: doc.data().answer,
          collection: doc.data().collection,
          id: doc.data().id,
          user: user,
        }

        final.push(q)
      })

      let numReads = querySnapshot.docs.length
      console.log(`${numReads} documents read.`)
    })
    .then(() => {
      setTimeout(
        () =>
          dispatch({
            type: GET_QUESTIONS,
            payload: final,
          }),
        1250
      )
    })
}

// Reducers
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTIONS:
      state.firebaseQuestions = []
      action.payload.map(question => state.firebaseQuestions.push(question))
      return {
        ...state,
        firebaseQuestions: [...state.firebaseQuestions],
        loading: false,
      }

    case SET_LOADING:
      return {
        ...state,
        loading: true,
      }

    case ADD_QUESTION:
      return {
        ...state,
        firebaseQuestions: [action.payload, ...state.firebaseQuestions],
      }

    case REMOVE_QUESTION:
      return {
        ...state,
        firebaseQuestions: state.firebaseQuestions.filter(
          question => question.id !== action.payload.id
        ),
      }

    case CLEAR_ALL_QUESTIONS:
      return {
        ...state,
        firebaseQuestions: [],
      }

    case SET_USER:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        loading: false,
      }

    case SIGNOUT_USER:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        firebaseQuestions: [],
        loading: false,
      }

    default:
      return state
  }
}

// Support functions
const addToFirebase = question => {
  const firestore = getFirestore()

  let uniqueId = question.user.email + question.id

  // Check for duplicate questions
  firestore
    .collection("questions")
    .doc(uniqueId)
    .get()
    .then(q => {
      if (!q.exists) {
        firestore
          .collection("questions")
          .doc(uniqueId)
          .set({
            question: question.question,
            answer: question.answer,
            collection: question.collection,
            type: question.type,
            id: question.id,
            user: question.user,
          })
      }
    })
}

const addUserToFirebase = user => {
  const firestore = getFirestore()
  const { email } = user

  firestore
    .collection("users")
    .doc(email)
    .get()
    .then(e => {
      if (!e.exists) {
        firestore
          .collection("users")
          .doc(email)
          .set({
            email: email,
            timestamp: new Date(),
          })
      }
    })
}

const removeFromFirebase = question => {
  const firestore = getFirestore()

  let docId = question.user.email + question.id

  firestore
    .collection("questions")
    .doc(docId)
    .delete()
    .catch(function(error) {
      console.error("Error removing document: ", error)
    })
}

const clearFirebase = user => {
  const firestore = getFirestore()
  let result = []
  let final = []

  // Get all questions a specific user saved and delete each question one-by-one
  firestore
    .collection("questions")
    .where("user.email", "==", user.email)
    .get()
    .then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        let q = {
          type: doc.data().type,
          question: doc.data().question,
          answer: doc.data().answer,
          collection: doc.data().collection,
          id: doc.data().id,
          user: user,
        }

        final.push(q)
      })

      let numReads = querySnapshot.docs.length
      console.log(`${numReads} documents read.`)
    })
    .then(() => {
      final.map(question => {
        removeFromFirebase(question)
      })
    })
}
